import React, { useState, useEffect } from "react";
import "./landing.css";

import arrow from "./assets/icons8-triangle-60.png";

const Team = () => {

    const [members, setMembers] = useState([]);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/members/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setMembers(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchMembers();
    }, []);


    return(
        <>
           {members.length === 0 ? (
                    <div className="loadingSpinnerWrapper">
                        <span className="loadingSpinner"></span>
                    </div>
                ) : (
                    members.map((membe) => (
                         <div className="teamCard" key={membe.id}>
                            <div className="memberBg">
                                <img src={membe.image} alt=""/>
                            </div>
                            <div className="memberInfo">
                                <div className="infoUpper">
                                    <div className="colorDiv">
    
                                    </div>
                                </div>
                                <div className="infoLower">
                                    <div className="memberName content">{membe.name}</div>
                                    <div className="memberPost content">{membe.role}</div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
        </>
    )
}

export default Team;