import React, { useState, useEffect } from "react";
import "./faqs.css";
import AccordionItem from "./AccordianItem";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const FAQ = () => {

  useEffect(() =>{
      window.scrollTo(0,0)
  },[]);

  // faqs
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
      const fetchFaqs = async () => {
          try {
              const response = await fetch(`${process.env.REACT_APP_base_url}/faqs/`, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
              }
              });

              if (response.ok) {
                  
              const data = await response.json();
              setFaqs(data.results);
              } else {
              console.error('Failed to fetch posts:', response.statusText);
              }
          } catch (error) {
              console.error('Error fetching posts:', error);
          }
      };

      fetchFaqs();
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const canonicalUrl = "https://www.barbola.org/faq"

  return (
    <div className="faqs">
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            <title>Barbola Construction | Frequently asked questions </title>
            <meta name="description" content="Barbola construction Frequently asked questions page" />
            {/* Add more meta tags as needed */}
        </Helmet>
      <section className="careersHeader">
        <h1 className="header headerB">
        Frequently Asked Questions
      </h1>
      </section>
      
      <p className="content">
        Do you need some help with something you have questions on some
        features?
      </p>
      <div className="container">
        {faqs.map((item, index) => (
          <AccordionItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={activeIndex === index}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </div>
      <div className="m-4">
        <h3 className="text-lg">
          Have any other <span className="fg-orange">questions?</span>
        </h3>
        <p className="d-flex content">
          Don't hesitate to send us an email with your enquiry or statement at{" "}
          <span className="email-info content"><a href="mailto:barbolacoltd@gmail.com">barbolacoltd@gmail.com</a></span>.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
