import React from "react";
import { Link } from "react-router-dom";
import "../landingPage/landing.css";

import heroBg from "../landingPage/assets/heroBg.jpg";
import arrow from "../landingPage/assets/icons8-triangle-60.png";
import ln from "../landingPage/assets/icons8-in-50.png";
import ig from "../landingPage/assets/icons8-ig-64.png";
import fb from "../landingPage/assets/icons8-fb-60.png";
import x from "../landingPage/assets/icons8-x-50.png";
import logo from "../navBar/assets/barbola_logo.webp";

import call from "../navBar/assets/icons8-call-ios-17-outlined/icons8-call-50.png";
import mail from "../navBar/assets/icons8-mail-ios-17-outlined/icons8-mail-50.png";
import location from "../navBar/assets/icons8-placeholder-62.png";

const Footer = ()=>{
    return(
        <div className="footer">
                <div className="footerOne">
                    <div className="footerA">
                        <div className="footerAA">
                            
                            <img src={logo} alt=""/>
                            <div>
                                barbola
                            </div>
                        </div>
                        <p className="content">
                            Barbola strives to be a thought leader in our industry and provides continous insights 
                            and updates to our clients
                        </p>
                        <div className="footerSocials">
                            <img src={ln} alt=""/>
                            <img src={fb} alt=""/>
                            <img src={x} alt=""/>
                            <img src={ig} alt=""/>
                        </div>
                        
                    </div>
                    <div className="footerB">
                        <div className="footerHead">
                                Quick links
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/about">About us</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/services">Services</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/ourwork">Our work</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/careers">Careers</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/contactus">Contact</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/faq">FAQs</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/privacypolicy">Privacy policy</Link>
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={arrow} alt=""/>
                            <div className="infoContent">
                                <Link to="/terms">Terms and conditions</Link>
                            </div>
                        </div>
                    </div>
                    <div className="footerC">
                    <div className="footerHead">
                            recent news
                        </div>
                        <div className="footerInfo">
                            <img src={heroBg} alt="" className="imgInfo"/>
                            <div className="infoContent">
                                how difficult road construction work than build
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={heroBg} alt="" className="imgInfo"/>
                            <div className="infoContent">
                                how difficult road construction work than build
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={heroBg} alt="" className="imgInfo"/>
                            <div className="infoContent">
                                how difficult road construction work than build
                            </div>
                        </div>
                        
                    </div>
                    <div className="footerD">
                    <div className="footerHead">
                            contact us
                        </div>
                        <div className="footerInfo">
                            <img src={location} alt=""/>
                            <div className="infoContent">
                            3876-30100, <br/>Eldoret, Kenya
                            </div>
                        </div>
                        <div className="footerInfo">
                            <img src={call} alt=""/>
                            <div className="infoContent">
                            <a href="tel:+254727683934">(254) 727-683-934</a> <br/><a href="tel:+254725360335">(254) 725-360-335</a>
                            </div>
                        </div>
                        <div className="footerInfo email">
                            <img src={mail} alt=""/>
                            <div className="infoContent">
                            <a href="mailto:barbolacoltd@gmail.com">barbolacoltd<br/>@gmail.com</a>
                            </div>
                        </div>
                        
                    </div>    
                </div>
                <div className="footerTwo">
                    <div className="copyright">
                        <div className="content">Copyright &copy; {new Date().getFullYear()}. Designed and Created by <a target="_blank" rel="noreferrer" to="https://www.dasce.dev">dasce.dev</a></div>
                    </div>
                </div>
                
            </div>
    )
}

export default Footer;