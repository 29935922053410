import React, { useState, useEffect } from "react";
import "./landing.css";

import arrow from "./assets/icons8-triangle-60.png";

const LandingServices = () => {

    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/services/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });
  
                if (response.ok) {
                    
                const data = await response.json();
                setServices(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
  
        fetchServices();
    }, []);


    return(
        <>
            {services.length === 0 ? (
                <div className="loadingSpinnerWrapper">
                    <span className="loadingSpinner"></span>
                </div>
            ) : (
            services.map((service) => (
                <div className="servicesCard">
                    <img src={service.image} alt={service.title} />
                    <h4 className="servicesTitle">{service.title}</h4> 
                    <h5 className="servicesContent" dangerouslySetInnerHTML={{ __html: service.description }}>
                    </h5>
                    <div className="sReadMore">
                        <img src={arrow} alt=""/>
                        <div className="sRMore">read more</div>
                    </div>
                </div>
                ))
            )} 
        </>
    )
}

export default LandingServices;