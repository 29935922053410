import React, { useEffect } from "react";
import ServicesCard from "./ServicesCard";
import "./services.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Services = () => {

  useEffect(() =>{
      window.scrollTo(0,0)
  },[]);

  const canonicalUrl = "https://www.barbola.org/services"
  
  return (
    <>
      <section className="careersHeader">
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            <title>Barbola Construction | Services  </title>
            <meta name="description" content="Barbola construction Services page" />
            {/* Add more meta tags as needed */}
        </Helmet>
         <h1 className="header headerMain">
        Our Services
      </h1>
      </section>
     
      <div className="services">
        <ServicesCard />
      </div>
    </>
  );
};

export default Services;
