import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./landing.css";

import arrow from "./assets/icons8-triangle-60.png";

const PostGallery = () => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/posts/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setPosts(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);


    return(
        <>
        {posts.map((post) => (
            <div className="blogCard" key={post.id}>
                <Link to={`/blog/${post.id}`}
                state={{ post }}>
                <img className="bcImg" src={post.image} alt={post.title}/>
                <div className="blogName">
                    {post.title}
                </div>
                {/* <p className="content blogExcerpt" dangerouslySetInnerHTML={{ __html: post.content }}>
                </p> */}
                <div className="blogRM">
                    <img src={arrow} alt=""/>
                    <div className="readMore content">read more</div>
                </div>
                </Link>
            </div>
        ))}
                    
        </>
    )
};

export default PostGallery