import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ourwork.css"; 
import { Helmet, HelmetProvider } from 'react-helmet-async';

import i1 from "./assets/i1.jpeg";
import i2 from "./assets/i2.jpeg"


const OurWork = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/projects/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setProjects(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchProjects();
    }, []);

    const canonicalUrl = "https://www.barbola.org/ourwork"

    return (
        <div className="our-work-page">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | Projects  </title>
                <meta name="description" content="Barbola construction Projects page" />
                {/* Add more meta tags as needed */}
            </Helmet>
            
            <div className="our-work-container">
            <section className="careersHeader">
                <h1 className="headerB">Our Work</h1>
            </section>
                <header className="our-work-header">
                    
                    <p>Explore some of the successful projects we have completed over the years.</p>
                </header>

                <section className="projects-grid">
                {projects.length === 0 ? (
                    <div className="loadingSpinnerWrapper loaderDiv">
                        <span className="loadingSpinner"></span>
                    </div>
                ) : (
                    projects.map((project) => (
                        <div key={project.id} className="project-card">
                            <Link to={`/ourwork/${project.id}`}
                            state={{ project }}>
                            <img src={project.image} alt={project.title} className="project-image" />
                            <div className="project-info">
                                <h3>{project.title}</h3>
                                <p className="content projectExcerpt" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                            </div>
                            </Link>
                        </div>
                    ))
                )}
                </section>
            </div>
            
        </div>
    );
};

export default OurWork;
