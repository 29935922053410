import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blog.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import blogImg from "./assets/8336.webp"

const Blog = () =>{

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/posts/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setPosts(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    const canonicalUrl = "https://www.barbola.org/blog"

    return(
        <div className="blogHome">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | Blog </title>
                <meta name="description" content="Barbola construction blog page" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <section className="blogHeader careersHeader">
                <h1 className="blogHead headerB">
                    blog
                </h1>
            </section>
            <section className="blogHero">
                <img src={blogImg} alt=""/>
            </section>
            <section className="postGallery">
                {posts.length === 0 ? (
                    <div className="loadingSpinnerWrapper">
                        <span className="loadingSpinner"></span>
                    </div>
                ) : (
                    posts.map((post) => (
                        <div className="blogPCard" key={post.id}>
                            <Link to={`/blog/${post.id}`}
                            state={{ post }}>
                            <div className="blogI">
                                <img src={post.image} alt={post.title} />
                            </div>
                            <div className="blogC">
                                {/* <h4 className="blogTag">{post.tag}</h4> */}
                                <h3 className="blogTitle">{post.title}</h3>
                                <div className="bcAuthor">
                                    <img src={post.image} alt={post.authorName} />
                                    <h5 className="bcName">{post.author?.username || 'Anonymous'}</h5>
                                    <h6 className="bcDate">{new Date(post.created_at).toLocaleDateString()}</h6>
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))
                )}
            </section>
            <div className="moreBtn">
                Load More
            </div>
        </div>
    )
}

export default Blog;