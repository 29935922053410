import React, { useEffect, useState } from "react";
import "./contactus.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import feature3 from "../../assets/featured-3.jpg";

const ContactUs = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsEmailValid(validateEmail(value));
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isEmailValid) {
            toast.error('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_base_url}/contact-us/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    full_name: fullName,
                    email: email,
                    message: message,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                toast.success('Message sent successfully!');
                // console.log('Message sent:', data);
            } else {
                toast.error('Failed to send message.');
                // console.error('Message failed:', response.statusText);
            }
        } catch (error) {
            toast.error('An error occurred while sending the message.');
            // console.error('Error:', error);
        }
    };

    const canonicalUrl = "https://www.barbola.org/contactus"
    
    return (
        <div className="contactus--box">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | Contact us  </title>
                <meta name="description" content="Barbola construction Contact us page" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <div className="contactus--image">
                <img src={feature3} alt="Featured" />
            </div>
            <form className="input--container" onSubmit={handleSubmit}>
                <h2 className="headerMain">Contact Us</h2>
                <input type="text"
                        placeholder="full name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)} 
                        className="box" 
                        required 
                        />
                <input type="email"
                        placeholder="email"
                        value={email}
                        onChange={handleEmailChange} 
                        className="box" 
                        required 
                        />
                <textarea value={message}
                        placeholder="your message"
                        onChange={(e) => setMessage(e.target.value)} 
                        className="box message" 
                        required
                        />
                <button type="submit" className="btn">Submit<i className="fas fa-paper-plane"></i></button>
            </form>
            <div className="contactinfo--container">
                <div className="contact">
                    <h3>Contact Information</h3>
                    <p className="content">Phone: <br/><a href="tel:+254727683934">(254) 727-683-934</a> <br/><a href="tel:+254725360335">(254) 725-360-335</a></p>
                    <p className="content">Email: <a href="mailto:barbolacoltd@gmail.com">barbolacoltd@gmail.com</a></p>
                </div>
                <div className="location">
                    <h3>Based in</h3>
                    <p className="content">3876-30100, Eldoret, Kenya</p>
                </div>
                <div className="socialmedia">
                    <h3>Follow Us</h3>
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                    <a href="#"><i className="fab fa-twitter-f"></i></a>
                    <a href="#"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default ContactUs;
