import React, {useEffect} from "react";
import "./careers.css";
import tick from "./assets/icons8-tick-48.png"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Careers = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const canonicalUrl = "https://www.barbola.org/careers"

    return(
        <>
        <div className="careersMain">
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            <title>Barbola Construction | Careers </title>
            <meta name="description" content="Barbola construction careers page" />
            {/* Add more meta tags as needed */}
        </Helmet>
            <section className="careersHeader">
                <h1 className="careersTitle headerMain">Careers</h1>
            </section>
            <section className="careersHero">
                <h2 className="contentTitle headerB">Why us?</h2>
                <p className="contentBody content">
                    At barbola, we believe in empowering and building
                     the capacity of our team to became their level best and perform 
                     optimally. That's why we think beyond a generous compesation package,
                     we understand that a great work life balance and exposure to interesting
                     and challenging projects keeps our team motivated and up to date on
                     emerging technologies. Join us today and be at the forefront in applying
                     practical solutions, creating durable solutions and creating long lasting
                     for our esteemed and valued clients as we build a better tomorrow.
                </p>
            </section>
            <section className="careersContent">
                <h2 className="contentTitle headerB">Join our team</h2>
                <p className="contentBody content">
                    We are always on the look out for great talent to join our team, we are particulary
                     keen to have competent, hardworking and dedicated individuals to join our team. We are
                     of the strong opinion that for any role at our organization, certain qualities cut across
                     and are the bare minimums for qualification at any of our roles. We are keen on talent who have:
                </p>
                <div className="points">
                    <div className="point">
                        <img src={tick} alt=""/><p className="contentBody content">Integrity</p>
                    </div>
                    <div className="point">
                        <img src={tick} alt=""/><p className="contentBody content">Commitment</p>
                    </div>
                    <div className="point">
                        <img src={tick} alt=""/><p className="contentBody content">Dedication</p>
                    </div>
                </div>
                <p className="contentBody content">
                    Do you check all the boxes above? Then you are just what we need on our team
                </p>

                <h2 className="contentTitle headerB">Apply now</h2>

                <p className="contentBody content">
                    <b>Check our current openings below to see if you can be a match for any of our open opportunities</b>
                </p>

                <p className="contentBody content">
                    <i>We do not have any open opportunities at the moment. Please check back soon as we are always on the lookout for great talent from time to time</i>
                </p>
            </section>
        </div>
        </>
    )
}

export default Careers;
