import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import blogImg from "./assets/8336.webp";

const SinglePost = () => {
    
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const location = useLocation();
    const { post } = location.state;

    const sanitizedContent = DOMPurify.sanitize(post.content);

    return (
        <div className="postWrapper">
            <div className="singlePost">
                {/* <h4 className="spAtag">{post.tag}</h4> */}
                <h1 className="headerB">{post.title}</h1>
                <div className="spAcard">
                    <img src={blogImg} alt="" />
                    <h5>{post.author?.username || 'Anonymous'}</h5>
                    <h5>{new Date(post.created_at).toLocaleDateString()}</h5>
                </div>
                <div className="spAimgHolder">
                    <img className="spAimg" src={post.image} alt={post.title} />
                </div>
                <p className="content" dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                
            </div>
        </div>
    );
};

export default SinglePost;