import React, { useEffect, useState } from "react";
import "./services.css";

const ServicesCard = () => {

   // faqs
   const [services, setServices] = useState([]);

   useEffect(() => {
       const fetchServices = async () => {
           try {
               const response = await fetch(`${process.env.REACT_APP_base_url}/services/`, {
               method: 'GET',
               headers: {
                   'Accept': 'application/json',
               }
               });
 
               if (response.ok) {
                   
               const data = await response.json();
               setServices(data.results);
               } else {
               console.error('Failed to fetch posts:', response.statusText);
               }
           } catch (error) {
               console.error('Error fetching posts:', error);
           }
       };
 
       fetchServices();
   }, []);


  return (
    <>
      {services.length === 0 ? (
          <div className="loadingSpinnerWrapper">
            <span className="loadingSpinner"></span>
        </div>
        ) : (
        services.map((service) => (
            <div key={service.id} className="services-card">
              <img src={service.image} className="services-icon" alt={service.title} />
              <h4 className="services-card-title headerB">{service.title}</h4>
              <ul className="services-card-body">
                
                  <p class="services-card-text content" dangerouslySetInnerHTML={{ __html: service.description }}></p>
              </ul>
            </div>
        ))
      )}
    </>
  );
};

export default ServicesCard;
