import React, {useEffect} from "react";
import feature1 from "../../assets/featured-2.jpg";
import feature2 from "../../assets/featured-1.jpg";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import "./aboutus.css";

const About = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const canonicalUrl = "https://www.barbola.org/about"

    return(
        <div className="about">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | About </title>
                <meta name="description" content="Barbola construction about page" />
                {/* Add more meta tags as needed */}
            </Helmet>
        <section className="careersHeader">
             <h1 className="about-title headerMain">
        About Us
        </h1>
        </section>
       
        <div className="underline-shadow"></div>
        
        <div className="aboutus--wrapper">
            <div className="aboutus--container">
                <p className="tag">Who We Are</p>
                <h1 className="headerB">Who We Are</h1>
                <p className="about--content content">
                    We are a company dedicated to providing high-quality, sustainable, and innovative construction solutions. 
                    Our mission is to create beautiful, safe, and efficient buildings that meet the needs of our clients and their communities.
                </p>
                <img src={feature1} alt="completed house" className="aboutus--image" />
            </div>
            <div className="aboutus--container">
                <p className="tag">Why Us</p>
                <h1 className="headerB">What We Do</h1>
                <p className="about--content content">
                    We specialize in residential, commercial, and mixed-use construction projects. Our team of experts has 
                    been working together for over 35 years to deliver exceptional quality and efficiency.
                </p>
                <img src={feature2} alt="our team on construction site" className="aboutus--image" />
            </div>
        </div>
    </div>
);
}


export default About;