import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './components/navBar/navBar';
import Footer from './components/footer/footer';
import LandingPage from './components/landingPage/landingPage';
import About from './components/aboutUs/aboutUs';
import Careers from './components/careers/careers';
import ContactUs from './components/contactUs/contactUs';
import OurWork from './components/ourWork/ourWork';
import SingleProject from './components/ourWork/singleProject';
import Disclaimer from './components/disclaimer/disclaimer';
import FAQ from './components/faq/faq';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import Services from './components/services/services';
import Terms from './components/termsAndConditions/termsAndConditions';
import Blog from './components/blog/blog';
import SinglePost from './components/blog/singlePost';
import PageNotFound from './components/pageNotFound/pageNotFound';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <meta
            http-equiv="Content-Security-Policy"
            content="
              object-src 'none';           
              frame-ancestors 'none';      
              upgrade-insecure-requests;  
            "
          />
        </Helmet>
      <NavBar/>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/ourwork" element={<OurWork />} />
          <Route path="/ourwork/:projectId" element={<SingleProject />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postId" element={<SinglePost />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/services" element={<Services />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      <Footer/>
        <ToastContainer />
      </div>
    </HelmetProvider>
  );
}

export default App;
