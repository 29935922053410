import { useRef } from 'react';

export const useScroll = (scrollAmount = 300, externalRef = null) => {
    const internalRef = useRef(null);
    const scrollerRef = externalRef || internalRef;

    const scrollLeft = () => {

        if (scrollerRef.current) {
            scrollerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        
        if (scrollerRef.current) {
            scrollerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return { scrollerRef, scrollLeft, scrollRight };
};
