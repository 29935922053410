import React, {useEffect} from "react";
import "./terms.css";

import { Helmet, HelmetProvider } from 'react-helmet-async';

const Terms = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const canonicalUrl = "https://www.barbola.org/terms"
    
    return (
        <div className="terms-container">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | Terms of Service </title>
                <meta name="description" content="Barbola construction Terms of Servicepage" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <header className="careersHeader">
                <h1 className="headerMain">Terms of Service</h1>
            </header>
            <main className="terms-main">
                <p className="content">
                    Welcome to our website! These Terms of Service ("Terms") govern your 
                    use of our website and services. By accessing or using our site, you 
                    agree to be bound by these Terms. If you do not agree with any part of 
                    these Terms, you should not use our site.
                </p>
                <h2 className="headerB">1. Acceptance of Terms</h2>
                <p className="content">
                    By using our website, you agree to comply with and be bound by these 
                    Terms. We may update these Terms from time to time, and your continued 
                    use of the site constitutes acceptance of the updated Terms.
                </p>
                <h2 className="headerB">2. Use of Our Site</h2>
                <p className="content">
                    You agree to use our site only for lawful purposes and in accordance 
                    with these Terms. You must not use the site:
                    <ul>
                        <p>In any way that violates any applicable local, national, or 
                        international law or regulation.</p>
                        <p>To transmit or procure the sending of any unsolicited or 
                        unauthorized advertising or promotional material.</p>
                        <p>To impersonate or attempt to impersonate us, our employees, 
                        another user, or any other person or entity.</p>
                    </ul>
                </p>
                <h2 className="headerB">3. Intellectual Property</h2>
                <p className="content">
                    The content on our site, including but not limited to text, graphics, 
                    logos, images, and software, is the property of us or our licensors 
                    and is protected by intellectual property laws. You may not reproduce, 
                    distribute, or create derivative works from any content on our site 
                    without our express written permission.
                </p>
                <h2 className="headerB">4. User Accounts</h2>
                <p className="content">
                    If you create an account on our site, you are responsible for maintaining 
                    the confidentiality of your account information and for all activities that 
                    occur under your account. You agree to notify us immediately of any 
                    unauthorized use of your account or any other breach of security.
                </p>
                <h2 className="headerB">5. Disclaimers</h2>
                <p className="content">
                    Our site is provided on an "as is" and "as available" basis. We make no 
                    representations or warranties of any kind, express or implied, regarding 
                    the operation or availability of the site or the information, content, 
                    materials, or products included on the site.
                </p>
                <h2 className="headerB">6. Limitation of Liability</h2>
                <p className="content">
                    To the fullest extent permitted by law, we will not be liable for any 
                    indirect, incidental, special, consequential, or punitive damages, or any 
                    loss of profits or revenues, whether incurred directly or indirectly, 
                    or any loss of data, use, goodwill, or other intangible losses, 
                    resulting from:
                    <ul>
                        <p>Your use or inability to use our site.</p>
                        <p>Any unauthorized access to or use of our servers and/or any personal 
                        information stored therein.</p>
                        <p>Any interruption or cessation of transmission to or from our site.</p>
                    </ul>
                </p>
                <h2 className="headerB">7. Governing Law</h2>
                <p className="content">
                    These Terms shall be governed by and construed in accordance with the laws of
                     [Your Country/State], without regard to its conflict of law principles. 
                     Any legal action or proceeding arising out of or related to these Terms 
                     shall be brought exclusively in the courts located in [Your Jurisdiction].
                </p>
                <h2 className="headerB">8. Contact Us</h2>
                <p className="content">
                    If you have any questions about these Terms, please contact us 
                    at <a href="mailto:barbolacoltd@gmail.com">barbolacoltd@gmail.com</a>.
                </p>
            </main>
            <footer className="terms-footer">
                {/* <p className="content">&copy; {new Date().getFullYear()} Your Company. All rights reserved.</p> */}
            </footer>
        </div>
    );
}

export default Terms;

