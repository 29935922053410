import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./landing.css";

import arrow from "./assets/icons8-triangle-60.png";

const Projects = () => {

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/projects/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setProjects(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchProjects();
    }, []);
    
    return(
        <>
        {projects.length === 0 ? (
                <div className="loadingSpinnerWrapper">
                    <span className="loadingSpinner"></span>
                </div>
            ) : (
            projects.map((project) => (
                <div className="projectsCard" key={project.id}>
                    <Link to={`/ourwork/${project.id}`}
                    state={{ project }}>
                        <img src={project.image} alt=""/>
                        <p className="content pcTcontent">{project.title}</p>
                    </Link>
                    
                </div>
            ))
        )}
        </>
    )
}

export default Projects;