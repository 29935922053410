import React, { useState } from "react";
import "./nav.css";
import { Link, NavLink } from "react-router-dom";

import logo from "./assets/barbola_logo.webp";
import location from "./assets/icons8-placeholder-62.png";
import call from "./assets/icons8-call-ios-17-outlined/icons8-call-50.png";
import mail from "./assets/icons8-mail-ios-17-outlined/icons8-mail-50.png";
import search from "./assets/icons8-search-ios-17-glyph/icons8-search-60.png";

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
        setIsOpen(!isOpen)
    };
    return(
        <>
        <div className="navMain">
            <div className="topNav">
                <div className="topNavA">
                    <Link to="/"><img className="navLogo" src={logo} alt=""/></Link>
                    <div className="cCname">
                    <Link to="/">barbola</Link>
                    </div>
                </div>
                <div className="topNavB">
                    <img src={call} alt=""/>
                    <div className="topInfo">
                        <div className="infoName">
                            call us
                        </div>
                        <div className="infoValue">
                        <a href="tel:+254-727-683934">(+254)727683934</a>
                        </div>
                    </div>
                </div>
                <div className="topNavC">
                <img src={mail} alt=""/>
                    <div className="topInfo">
                        <div className="infoName">
                            mail us
                        </div>
                        <div className="infoValue">
                        <a href="mailto:barbolacoltd@gmail.com">barbolacoltd@gmail.com</a>
                        </div>
                    </div>
                </div>
                <div className="topNavD">
                <img src={location} alt=""/>
                    <div className="topInfo">
                        <div className="infoName">
                            address
                        </div>
                        <div className="infoValue">
                        3876-30100, Eldoret, Kenya
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="menuToggle" onClick={handleClick}>
                <div className={`bar ${clicked ? 'clicked' : ''}`}></div>
                <div className={`bar ${clicked ? 'clicked' : ''}`}></div>
                <div className={`bar ${clicked ? 'clicked' : ''}`}></div>
            </div>
            <div className="nav">
            
                <div className={`navLinks ${isOpen && "open"}`}>
                    <NavLink to="/" activeClassName="active" className="navlink" onClick ={handleClick}>home</NavLink>
                    <NavLink to="/about" activeClassName="active" className="navlink" onClick ={handleClick}>about us</NavLink>
                    <NavLink to="/services" activeClassName="active" className="navlink" onClick ={handleClick}>services</NavLink>
                    <NavLink to="/ourwork" activeClassName="active" className="navlink" onClick ={handleClick}>our work</NavLink>
                    <NavLink to="/careers" activeClassName="active" className="navlink" onClick ={handleClick}>careers</NavLink>
                    <NavLink to="/contactus" activeClassName="active" className="navlink" onClick ={handleClick}>contact</NavLink>
                    {/* <NavLink to="/" activeClassName="active" className="navlink navSearch" onClick ={handleClick}><img src={search} alt=""/></NavLink> */}
                    <NavLink to="/blog" activeClassName="active" className="navlink" onClick ={handleClick}>blog</NavLink>
                </div>
            </div>

            
        </div>
        </>
    )
}

export default NavBar;