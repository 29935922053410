import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./landing.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useScroll } from "../../hooks/sharedScrollHook";

import heroBg from "./assets/heroBg.jpg";
import works1 from "./assets/consw.webp";
import tick from "./assets/icons8-tick-48.png";
import arrowBlack from "./assets/icons8-triangle-black.png";
import arrow from "./assets/icons8-triangle-60.png";
import ln from "./assets/icons8-in-50.png";
import exp from "./assets/icons8-experience-100.webp";
import cli from "./assets/icons8-group-100.webp";
import PostGallery from "./postGallery";
import LandingServices from "./landingServices";
import Projects from "./projects";
import Team from "./team";
import Testimonials from "./testimonials";

const LandingPage = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const scrollerRef1 = useRef(null);
    const scrollerRef2 = useRef(null);
    const scrollerRef3 = useRef(null);
    const scrollerRef4 = useRef(null);
    const scrollerRef5 = useRef(null);

    const { scrollLeft: scrollLeft1, scrollRight: scrollRight1 } = useScroll(300, scrollerRef1);
    const { scrollLeft: scrollLeft2, scrollRight: scrollRight2 } = useScroll(300, scrollerRef2);
    const { scrollLeft: scrollLeft3, scrollRight: scrollRight3 } = useScroll(300, scrollerRef3);
    const { scrollLeft: scrollLeft4, scrollRight: scrollRight4 } = useScroll(300, scrollerRef4);
    const { scrollLeft: scrollLeft5, scrollRight: scrollRight5 } = useScroll(300, scrollerRef5);

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

        useEffect(() => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email === '') {
                setValidationMessage('');
                setIsValidEmail(false);
            } else if (emailRegex.test(email)) {
                setValidationMessage('Email is valid');
                setIsValidEmail(true);
            } else {
                setValidationMessage('Please enter a valid email address');
                setIsValidEmail(false);
            }
        }, [email]);

        const handleSubscribe = async () => {
            if (!isValidEmail) {
                toast.error('Please enter a valid email address.');
                return;
            }

        try {
        const response = await fetch(`${process.env.REACT_APP_base_url}/newsletter/subscribe/`, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            email: email, // Use the state value for the email
            }),
        });

        if (response.ok) {
            const data = await response.json();
            // console.log('Subscription successful:', data);
            toast.success('Subscription successful');
        } else {
            // console.error('Subscription failed:', response.statusText);
            toast.error(response.statusText)
        }
        } catch (error) {
        // console.error('Error:', error);
        toast.error(error)
        }
    };

    const canonicalUrl = "https://www.barbola.org/"

    return(
        <>
        <div className="landingMain">
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            <title>Barbola Construction | Home </title>
            <meta name="description" content="Barbola construction homepage" />
            {/* Add more meta tags as needed */}
        </Helmet>
            <div className="hero">
                <div className="heroImage">
                </div>
                <div className="heroText">
                    <h1 className="headerMain">
                        <span className="accent">make <span className="lowerCaps">it</span> easier </span><span className="lowerCaps">to</span> <br className="brOne"/>manage construction
                    </h1>
                    <p className="content">
                        Take control of construction outcomes, minimise risk and protect profits with
                        our top rated construction management platform
                    </p>
                    <div className="button lbtn bbbtn"><Link to="/about">learn more</Link></div>
                </div>
            </div>
            <div className="aboutH">
                <div className="aboutHA">
                    <h2 className="headerB aboutTitle">we build everything as your <span className="accent">dream</span></h2>
                    <p className="content">You can dream, create, design and build the most wonderful place in the world. But it requires poeple
                    our projects reflect the diversity of our clients and the communities that we serve. Take a look - no two efforts are alike. from 
                    large-scale projects to handcrafted finishes, the common thread through all our projects is finding the right solution for each.
                    </p>
                    <div className="features">
                        <div className="feature">
                            <img src={tick} alt=""/>
                            <h4 className="feat">100% satsifaction guarantee</h4>
                        </div>
                        <div className="feature">
                            <img src={tick} alt=""/>
                            <h4 className="feat">accurate testing process</h4>
                        </div>
                        <div className="feature">
                            <img src={tick} alt=""/>
                            <h4 className="feat">20+ years of experience</h4>
                        </div>
                        <div className="feature">
                            <img src={tick} alt=""/>
                            <h4 className="feat">strong building materials</h4>
                        </div>
                        <div className="feature">
                            <img src={tick} alt=""/>
                            <h4 className="feat">eco-friendly building materials</h4>
                        </div>
                    </div>
                    <div className="button lbtn"><Link to="/about">explore about us</Link></div>
                </div>
                <div className="aboutHBB">
                <div className="aboutHB">
                    <div className="ImgTint">
                        <img src={works1} alt=""/>
                        <div className="imgHolderTint">
                            <div className="tintDiv tintDivOne"></div>
                        </div>
                    </div>
                    <div className="infoCard">
                        <div className="imgHolder">
                            <img src={exp} alt=""/>
                            
                        </div>
                        <div className="infoHolder">
                            <h4 className="infoTitle">20+ years</h4>
                            <h5 className="infoContent">of experience</h5>
                        </div>
                    </div>
                    
                </div>
                <div className="aboutHC">
                    <div className="infoCard">
                        <div className="imgHolder">
                            <img src={cli} alt=""/>
                            
                        </div>
                        <div className="infoHolder">
                            <h4 className="infoTitle">100+ clients</h4>
                            <h5 className="infoContent">satsified by us</h5>
                        </div>
                    </div>
                    <div className="ImgTint">
                        <img src={works1} alt=""/>
                        <div className="imgHolderTint">
                            <div className="tintDiv tintDivTwo"></div>
                        </div>
                    </div>
                    
                </div>
                </div>
                
            </div>
            <div className="servicesH">
                <div className="servicesHA">
                    <h2 className="headerB servicesHead">
                        we provide strong<br/> services for you
                    </h2>
                    <p className="content">
                        our suite of integrated services ensure highest degrees of quality,
                         safety, efficiency and innovation on your project
                    </p>
                    <div className="button lbtn lbtnS btWhite"><Link to="/services">explore all services</Link></div>
                </div>
                
                <div className="servicesHB">
                    <div className="projectsSliderControls servicesControlH">
                        <span className="fs" onClick={scrollLeft1}><img className="rot" src={arrowBlack} alt=""/></span>
                        <span className="fsB" onClick={scrollRight1}><img src={arrowBlack} alt=""/></span>
                    </div>
                    <div className="scroller" ref={scrollerRef1}>
                        <LandingServices/>
                    </div>
                </div>
            </div>
            <div className="worksH">
                <div className="worksHA">
                    <div className="worksHAtext">
                        <h2 className="headerB worksTitle">
                            see how we manage our <span className="accent">works</span>
                        </h2>
                        <p className="content">
                            each of our projects demand unique, client-focused solutions, built on the 
                            solid knowledge base that we've developed from our diverse portfolio. The result 
                            is a broad range of multi-faceted services - executed via a proven, highly disciplined process.
                        </p>
                    </div>
                    <div className="steps">
                        <div className="step">
                            <img src={arrow} alt=""/>
                            <div className="stepName">
                                project<br/>planning
                            </div>
                        </div>
                        <div className="stepHiglight"></div>
                        <div className="step">
                            <img src={arrow} alt=""/>
                            <div className="stepName">
                                general<br/>contract
                            </div>
                        </div>
                        <div className="stepHiglight"></div>
                        <div className="step">
                            <img src={arrow} alt=""/>
                            <div className="stepName">
                                project<br/>starting
                            </div>
                        </div>
                    </div>
                </div>
                <div className="worksHB">
                    <div className="worksHBbg"></div>
                    <div className="video">

                    </div>
                </div>
            </div>
            <div className="projectsH">
                <div className="projectsHA">
                    <h2 className="headerB projectsTitle">
                        some of our finished projects that<br/>will amaze you
                    </h2>
                    <div className="projectsSliderControls">
                        <span className="fs" onClick={scrollLeft2}><img className="rot" src={arrowBlack} alt=""/></span>
                        <span className="fsB" onClick={scrollRight2}><img src={arrowBlack} alt=""/></span>
                    </div>
                </div>
                <div className="projectsHB" ref={scrollerRef2}>
                   <Projects/>
                </div>
                <div className="button lbtn hollowB">
                    <Link to="/ourwork">explore all projects</Link> 
                </div>
            </div>
            <div className="teamH">
                <div className="teamHA">
                    <h2 className="headerB teamTitle">
                        we have extraordinary members
                    </h2>
                    <p className="content">
                        we are a rapidly growing, highly diversified service 
                        provider seeking experienced professsionals, skilled crafts
                         poeple, and college recruits to join our industry-leading team.
                    </p>
                    {/* <div className="button lbtn blackB">
                        view all members
                    </div> */}
                    <div className="projectsSliderControls memberSliderControls">
                        <span className="fs" onClick={scrollLeft3}><img className="rot" src={arrowBlack} alt=""/></span>
                        <span className="fsB" onClick={scrollRight3}><img src={arrowBlack} alt=""/></span>
                    </div>
                </div>
                <div className="teamHB" ref={scrollerRef3}>
                        <Team/>     
                </div>
            </div>
            <div className="testimonialsH">
                <div className="testimonialsHA">
                    <h2 className="headerB testimonialsTitle">thoughts of our satsified clients about us</h2>
                    <div className="projectsSliderControls servicesControl">
                        <span className="fs" onClick={scrollLeft5}><img className="rot" src={arrowBlack} alt=""/></span>
                        <span className="fsB" onClick={scrollRight5}><img src={arrowBlack} alt=""/></span>
                    </div>
                </div>
                <div className="testimonialsHB">
                    <div className="testiBg">
                        <img src={heroBg} alt=""/>
                    </div>
                    <div className="testiFg" ref={scrollerRef5}>
                    <Testimonials/>
                        {/* <div className="testiCard">
                            <img src={flat} alt=""/>
                            <div className="cName">
                                Brendan Bramford
                            </div>
                            <div className="cOrg content">
                                Skyline Investiments
                            </div>
                            <div className="cTesti content">
                                "I love the hum of a construction and seeing the inner workings of a job site, 
                                and I pride herself on her poeple skills. Knowing that that workers can sit down 
                                with her to talk about anything. at the end of the day, her job is to send everyone home safely everyday"
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="blogH">
                <div className="blogHA">
                    <h2 className="headerB blogTitle">
                        read our blog to have clear <br/>knowledge about us
                    </h2>
                    <div className="projectsSliderControls servicesControl">
                        <span className="fs" onClick={scrollLeft4}><img className="rot" src={arrowBlack} alt=""/></span>
                        <span className="fsB" onClick={scrollRight4}><img src={arrowBlack} alt=""/></span>
                    </div>
                </div>
                <div className="blogHB" ref={scrollerRef4}>
                    <PostGallery/>
                </div>
                <div className="button lbtn buttonB">
                    <Link to="/ourwork">explore all projects</Link>
                </div>
            </div>
            <div className="clients">
                <img src={ln} alt=""/>
                <img src={ln} alt=""/>
                <img src={ln} alt=""/>
                <img src={ln} alt=""/>
                <img src={ln} alt=""/>
                <img src={ln} alt=""/>
            </div>
            <div className="newsletter">
                <img src={heroBg} alt=""/>
                <div className="newsletterForm">
                    <h2 className="headerB newsletterTitle">
                        subscribe to our newsletter
                    </h2>
                    <p className="content">
                        you can dream, create, design and build the most wonderful place in the world. 
                        but it requires poeple to make the dream
                    </p>
                    <p className="content" style={{ color: isValidEmail ? 'green' : 'red' }}>{validationMessage}</p>
                    <div className="formComponent">
                        <form>
                            <div>
                                <label onClick={handleSubscribe}>subscribe</label>
                                <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="e-mail"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
        </>
    )
}

export default LandingPage;