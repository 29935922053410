import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./ourwork.css";

const SingleProject = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const location = useLocation();
    const { project } = location.state;

    return(
        <div className="singleProjectsHome">
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="project-info">
                <h3>{project.title}</h3>
                <p className="content" dangerouslySetInnerHTML={{ __html: project.description }}></p>
            </div>
        </div>
    )
}

export default SingleProject;