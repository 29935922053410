import React, {useEffect} from "react";
import "./privacyPolicy.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PrivacyPolicy = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const canonicalUrl = "https://www.barbola.org/privacypolicy"
    
    return (
        <div className="privacy-container">
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>Barbola Construction | Privacy Policy  </title>
                <meta name="description" content="Barbola construction Privacy Policy page" />
                {/* Add more meta tags as needed */}
            </Helmet>
            <header className="careersHeader">
                <h1 className="headerMain">Privacy Policy</h1>
            </header>
            <main className="privacy-main">
                <p className="content">
                    Your privacy is important to us. This Privacy Policy explains how we 
                    collect, use, disclose, and safeguard your information when you visit 
                    our website. Please read this Privacy Policy carefully. If you do not 
                    agree with the terms of this Privacy Policy, please do not access the 
                    site.
                </p>
                <h2 className="headerB">1. Information Collection</h2>
                <p className="content">
                    We may collect information about you in a variety of ways. 
                    The information we may collect on the site includes:
                    <ul>
                        <p>Personal Data: Personally identifiable information, 
                        such as your name, shipping address, email address, and telephone 
                        number, and demographic information, that you voluntarily give to 
                        us when you register with the site or when you choose to participate 
                        in various activities related to the site.</p>
                        <p>Derivative Data: Information our servers automatically collect 
                        when you access the site, such as your IP address, your browser type, 
                        your operating system, your access times, and the pages you have 
                        recently viewed.</p>
                        <p>Financial Data: Financial information, such as data related to your 
                        payment method (e.g., valid credit card number, card brand, expiration date) 
                        that we may collect when you purchase, order, return, exchange, or request 
                        information about our services from the site.</p>
                    </ul>
                </p>
                <h2 className="headerB">2. Use of Your Information</h2>
                <p className="content">
                    Having accurate information about you permits us to provide you with a smooth, 
                    efficient, and customized experience. Specifically, we may use information 
                    collected about you via the site to:
                    <ul>
                        <p>Assist law enforcement and respond to subpoenas.</p>
                        <p>Compile anonymous statistical data and analysis for use internally or with 
                        third parties.</p>
                        <p>Create and manage your account.</p>
                        <p>Deliver targeted advertising, coupons, newsletters, and other information 
                        regarding promotions and the site to you.</p>
                        <p>Request feedback and contact you about your use of the site.</p>
                        <p>Monitor and analyze usage and trends to improve your experience with the site.</p>
                    </ul>
                </p>
                <h2 className="headerB">3. Disclosure of Your Information</h2>
                <p className="content">
                    We may share information we have collected about you in certain situations. 
                    Your information may be disclosed as follows:
                    <ul>
                        <p>By Law or to Protect Rights: If we believe the release of information about 
                        you is necessary to respond to legal process, to investigate or remedy potential 
                        violations of our policies, or to protect the rights, property, and safety of 
                        others, we may share your information as permitted or required by any applicable 
                        law, rule, or regulation.</p>
                        <p>Business Transfers: We may share or transfer your information in connection 
                        with, or during negotiations of, any merger, sale of company assets, financing, 
                        or acquisition of all or a portion of our business to another company.</p>
                        <p>Third-Party Service Providers: We may share your information with third-party 
                        service providers that perform services for us or on our behalf, such as payment 
                        processing, data analysis, email delivery, hosting services, customer service, 
                        and marketing assistance.</p>
                    </ul>
                </p>
                <h2 className="headerB">4. Security of Your Information</h2>
                <p className="content">
                    We use administrative, technical, and physical security measures to help protect your 
                    personal information. While we have taken reasonable steps to secure the personal 
                    information you provide to us, please be aware that despite our efforts, no security 
                    measures are perfect or impenetrable, and no method of data transmission can be 
                    guaranteed against any interception or other type of misuse.
                </p>
                <h2 className="headerB">5. Changes to This Privacy Policy</h2>
                <p className="content">
                    We may update this Privacy Policy from time to time in order to reflect, for example, 
                    changes to our practices or for other operational, legal, or regulatory reasons. 
                    We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>
                <p className="content">
                    You are advised to review this Privacy Policy periodically for any changes. 
                    Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
            </main>
            <footer className="privacy-footer">
                {/* <p className="content">&copy; {new Date().getFullYear()} Your Company. All rights reserved.</p> */}
            </footer>
        </div>
    );
}

export default PrivacyPolicy;

