import React from "react";
import "../landingPage/landing.css";
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return(
        <div className="pnfWrapper">
            <div className="pnfImg">
                <div className="pnfText">
                    <h1 className="headerB">OOPS! THAT PAGE CANNOT BE FOUND<br/>GO BACK <Link to="/">HOME</Link></h1>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;