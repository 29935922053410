import React, { useState, useEffect } from "react";
import "./landing.css";

import flat from "./assets/flat.png";

const Testimonials = () => {

    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_base_url}/testimonials/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
                });

                if (response.ok) {
                    
                const data = await response.json();
                setTestimonials(data.results);
                } else {
                console.error('Failed to fetch posts:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchTestimonials();
    }, []);

    return(
        <>
        {testimonials.length === 0 ? (
            <div className="loadingSpinnerWrapper">
                <span className="loadingSpinner"></span>
            </div>
        ) : (
            testimonials.map((testimonial) => (
                <div className="testiCard" key={testimonial.id}>
                    <img src={flat} alt=""/>
                    <div className="cName">
                        {testimonial.client_name}
                    </div>
                    <div className="cOrg content">
                        {testimonial.company}
                    </div>
                    <div className="cTesti content" dangerouslySetInnerHTML={{ __html: testimonial.summary }}>
                    </div>
                </div>
            ))
        )}
        </>
    )
}

export default Testimonials;